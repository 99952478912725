<template>
   <!-- 第一个导航-》学生的学校列表 -->
   <!-- 校园筛查 筛查计划管理 学生导入页面 -->
  <div class="page">
    <div class="header">
      <div class="header-button">
        <el-button type="primary" size="mini" round @click="goTo('/detection/importStudent')">批量导入学生</el-button>
      </div>
    </div>

    <!--  导航栏-->
    <div class="importList">
      <div class="schoolList">
        <el-link
          :underline="false"
          @click="goTo('/detection/schoolList')">
          已导入学生的学校列表
        </el-link>
      </div>
      <div class="studentList">
        <el-link
          style="color: #333"
          :underline="false"
          @click="goTo('/detection/student')"
        >
          已导入学生列表
        </el-link>
      </div>
    </div>

    <div class="select">
      <el-select v-model="schoolId" size="mini" filterable :filter-method="searchSchool" placeholder="请选择学校" @change="changeGradeSelect">
        <el-option
          v-for="item in schoolOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select v-model="gradeId" size="mini" clearable placeholder="请选择年级" @change="getClassSelect">
        <el-option
          v-for="item in gradeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select v-model="classId" size="mini" clearable placeholder="请选择班级" @change="search">
        <el-option
          v-for="item in classOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-input size="mini" placeholder="请输入内容" v-model="searchValue" class="input-with-select">
      </el-input>
      <el-button type="primary" size="small" @click="search()">提交</el-button>
      <el-button size="small" @click="resetForm()">重置</el-button>
    </div>

    <div class="content">
      <div class="table-main">
        <el-table :data="tablePageData" width="100%"
                  :cell-style="rowClass"
                   @selection-change="handleSelectionChange" :row-class-name="tableRowClassName">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="nickName" label="姓名">
          </el-table-column>
          <el-table-column prop="studentId" label="学号"  align="center">
          </el-table-column>
          <el-table-column prop="sex" label="性别"  align="center">
          </el-table-column>
          <el-table-column prop="birthday" :formatter="formatterDate" label="出生日期" align="center">
          </el-table-column>
          <el-table-column prop="idCard" label="身份证号" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系手机号" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="学校" align="center">
          </el-table-column>
          <el-table-column prop="gradeName" label="年级" align="center">
          </el-table-column>
          <el-table-column prop="className" label="班级" align="center">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button  size="mini" type="text" @click="goTo('/detection/editStudentInfo',scope.row)">修改</el-button>
              <el-button  size="mini" type="text" style="color: #FF0000;" slot="reference" @click="delChildren(0,scope.$index,scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
          <div slot="empty">
            <div :style="{'marginTop' : '52px'}">
              <img src="@/assets/detection/image/404.png" alt="" width="333">
            </div>
          </div>
        </el-table>

        <!--    分页和批量删除    -->
        <div class="table-page">
          <el-button
            :type="batchNum?'primary':'disabled'"
            :disabled="batchNum?false:true"
            size="mini"
            class="delete-button" round
            @click="delChildren(1)"
          >
            批量删除({{ batchNum }})
          </el-button>
          <pagination :current-page="startPage" :total="total" :page-size="pageSize" @currentChange="handleCurrentChange" @sizeChange="setPageSize"></pagination>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Moment from 'moment'
import selectService from '@/globals/service/detection/select.js'
import StudentsService from '@/globals/service/detection/Students.js'
import schoolService from '@/globals/service/detection/school.js'
import studentService from '@/globals/service/management/student.js'

export default {
  data () {
    return {
      schoolId: null,
      gradeId: null,
      classId: null,
      school: null,
      newGradeId: null,
      schoolClassId: null,
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      searchValue: null,
      nickName: '',
      total: 0,
      startPage: 1,
      pageSize: 8,
      startPageSchool: 1,
      pageSizeSchool: 50,
      tableData: [],
      tablePageData: [],
      multipleSelection: [],
      batchNum: 0,
      selectRow: []
    }
  },
  mounted () {
    const row = this.$route.query
    this.getSchoolSelect(row)
    // this.schoolId = Number(this.$route.query.schoolId)
  },
  methods: {
    setPageSize (value) {
      this.pageSize = value
      this.getListData()
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    resetForm () {
      this.schoolId = ''
      this.gradeId = ''
      this.classId = ''
      this.searchValue = ''
      this.search()
    },
    getListData () {
      const jsonObj = {
        school: this.school,
        newGradeId: this.newGradeId,
        schoolClassId: this.schoolClassId,
        nickName: this.nickName,
        startPage: this.startPage,
        pageSize: this.pageSize
      }
      StudentsService.list(jsonObj).then(res => {
        if (res.data.totalPage) {
          this.total = res.data.totalPage
          this.tablePageData = res.data.childrenList.map(item => {
            if (!item.idCard)item.idCard = '-'
            if (!item.studentId)item.studentId = '-'
            if (!item.phone)item.phone = '-'
            if (!item.gradeName)item.gradeName = '-'
            if (!item.className)item.className = '-'

            return item
          })
        } else {
          this.total = 0
          this.tablePageData = []
        }
      })
    },
    searchSchool (row) {
      schoolService.search({ name: row }).then(res => {
        const schoolList = res.data || []
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
      })
    },
    getSchoolSelect (row) {
      selectService.school({ startPage: this.startPageSchool, pageSize: this.pageSizeSchool }).then(res => {
        const schoolList = res.data.schoolList
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
        if (row.schoolId !== undefined && row.schoolId !== null) {
          this.schoolId = Number(row.schoolId)
          this.getGradeSelect(row)
        }
      })
    },
    // getSchoolSelect (row) {
    //   this.schoolId = ''
    //   this.gradeId = null
    //   this.classId = null
    //   this.schoolOptions = []
    //   this.gradeOptions = []
    //   this.classOptions = []
    //   selectService.school({ startPage: this.startPageSchool, pageSize: this.pageSizeSchool }).then(res => {
    //     const schoolList = res.data.schoolList
    //     const schoolOptions = []
    //     schoolList.forEach(item => {
    //       schoolOptions.push({
    //         value: item.id,
    //         label: item.schoolName
    //       })
    //     })
    //     if (schoolOptions.length > 0) {
    //       if (row.schoolId) {
    //         this.schoolId = parseInt(row.schoolId)
    //       } else {
    //         this.schoolId = schoolOptions[0].value
    //         this.school = schoolOptions[0].value
    //         return this.getListData()
    //       }
    //       this.search()
    //     } else {
    //       this.$alert('未找到可选的学校，请添加', '提示', {
    //         type: 'info',
    //         center: true
    //       })
    //     }
    //     console.log(schoolOptions)
    //     this.schoolOptions = schoolOptions
    //     this.getGradeSelect(row)
    //   })
    // },
    changeGradeSelect (e) {
      this.getGradeSelect(e)
    },
    getGradeSelect (row) {
      if (!this.schoolId) {
        return false
      }
      this.searchSchool('')
      this.gradeId = null
      this.classId = null
      this.gradeOptions = []
      this.classOptions = []
      this.search()
      selectService.grade({ schoolId: this.schoolId + '' }).then(res => {
        const gradeList = res.data
        const gradeOptions = []
        gradeList.forEach(item => {
          gradeOptions.push({
            value: item.id,
            label: item.gradeName
          })
        })
        if (gradeOptions.length > 0) {
          if (row.gradeId) {
            this.gradeId = parseInt(row.gradeId)
          }
        }
        this.gradeOptions = gradeOptions
        this.search()
        this.getClassSelect(row)
      })
    },
    getClassSelect (row) {
      this.classId = null
      this.classOptions = []
      if (!this.gradeId) {
        return this.search()
      }
      this.classOptions = []
      this.search()
      selectService.class({ gradeId: this.gradeId + '' }).then(res => {
        const classList = res.data
        const classOptions = []
        classList.forEach(item => {
          classOptions.push({
            value: item.id,
            label: item.className
          })
        })
        if (classOptions.length > 0) {
          if (row.classId) {
            this.classId = parseInt(row.classId)
            this.search()
          }
        }
        this.search()
        this.classOptions = classOptions
      })
    },
    search () {
      this.startPage = 1
      this.school = this.schoolId ? this.schoolId : null
      this.newGradeId = this.gradeId ? this.gradeId : null
      this.schoolClassId = this.classId ? this.classId : null
      this.nickName = this.searchValue
      this.getListData()
    },

    handleCurrentChange (val) {
      this.startPage = val
      this.getListData()
    },
    formatterDate (row, column, cellValue) {
      if (cellValue === '' || cellValue === null) {
        return '-'
      } else {
        return Moment(parseInt(cellValue)).format('YYYY-MM-DD')
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    // 删除
    delChildren (type, index, id) {
      let ids = []
      if (type === 1) {
        this.$confirm('请确认要全部删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const multipleSelection = this.multipleSelection
          multipleSelection.forEach(item => {
            ids.push(item.id)
          })

          // const loading = this.$loading({
          //   lock: true,
          //   text: 'Loading',
          //   spinner: 'el-icon-loading',
          //   background: 'rgba(0, 0, 0, 0.7)'
          // })

          studentService.delStudent({ childrenIdList: ids }).then(res => {
            setTimeout(() => {
              // loading.close()
              this.$message({ type: 'success', message: '删除成功!' })
              this.getListData()
            }, 2000)
          })
        }).catch(() => {
          this.$message({ type: 'info', message: '已取消删除' })
          return false
        })
      } else {
        this.$confirm('此操作将永久删除当前所选, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          ids = [id]
          studentService.delStudent({ childrenIdList: ids }).then(res => {
            this.$message({ type: 'success', message: '删除成功!' })
            this.tablePageData.splice(index, 1)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    handleSelectionChange (rows) {
      this.multipleSelection = rows
      this.batchNum = rows.length
    },
    rowClass ({ row, rowIndex }) {
      if (this.selectRow.includes(row.id)) {
        return { 'background-color': 'rgba(185, 221, 249, 0.75)' }
      }
    }
  },
  watch: {
    multipleSelection (data) {
      this.selectRow = []
      if (data.length > 0) {
        data.forEach((item, index) => {
          this.selectRow.push(item.id)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  background: #fff;
  min-height: 100%;
  padding-bottom: 20px;
  .page .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0 16px;
  }

  .el-button {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 42px;
    border-bottom: 1px solid #CCCCCC;
    font-size: 0;
    color: #333;
    span {
      display: inline-block;
      font-size: 12px;
      box-sizing: border-box;
    }
  }
}

.el-button--info {
  background: #F1F1F1;
  color: #FF0000;
}

.importList {
  display: flex;
  height: 20px;
  margin: 10px 0 16px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #CCCCCC;
}

.schoolList, .studentList {
  display: flex;
  float: left;
  padding-right: 40px;
}

.select {
  display: flex;
  text-align: left;
  align-items: center;
   .el-input,.el-button,.el-select{
     margin-right: 10px;
   }

  select {
    width: 250px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    outline: none;
    margin-right: 8px;
  }

  select:last-child {
    width: 250px;
  }
  .el-input{
    max-width: 200px;
    margin-right: 10px;
  }
}

.search {
  width: 300px;
  height: 45px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  outline: none;
  margin-right: 8px;
}

.paging {
  display: flex;
  flex-grow: 1;
}

.bottom-paging {
  display: flex;
  justify-content: center;
}

.content {
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 20px 0;
}
.el-select .el-input {
    width: 130px;
  }
  .el-input-group__append{
    height: 28px;
  }
  .table-page{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
</style>
